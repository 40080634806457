import { Button } from "antd";
import styled from "styled-components";

export const Wrap = styled.div`
  .ant-form-item {
    height: max-content;
    width: 100%;
    margin-bottom: 0px;

    .ant-select {
      min-height: 40px !important;
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      .ant-select-selector {
        min-height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
        
      }
    }
    .ant-select-clear{
      display: flex !important;
    }

    .ant-form-item-row {
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-label {
      text-align: start;
    }

    .ant-select-disabled {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000 !important;
    }

    .select__menu {
      z-index: 10;
    }

    .select__control {
      border: ${({ validateStatus, theme }) =>
        validateStatus === "error"
          ? `1px solid ${theme.error_ant} !important`
          : ""};
      box-shadow: ${({ validateStatus }) =>
        validateStatus === "error" ? "none" : ""};
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }

    label::after {
      content: "" !important;
    }
    .ant-col.ant-form-item-label label{
      white-space: nowrap;
    }

  }
  thead tr th.priority{
    background-color: #55554b
  }
`;

export const FilterAction = styled.div`
  display: flex;
  gap: 0 15px;
  align-items: end;
  max-width: 1300px;
  :nth-child(1){
    align-items: start;
  }
  :nth-child(2){
    margin-top: 30px;
  }
  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
export const SelectItemLarge = styled.div`
  max-width: 260px;
  width: 100%;
`;
export const SelectItemSmall = styled.div`
  max-width: 150px;
  width: 100%;
`;
export const SelectItem = styled.div`
  max-width: 200px;
  width: 100%;
`;
export const ItemInput = styled.div`
  max-width: 400px;
  width: 100%;
`;
export const ItemInputImport = styled.div`
  max-width: 260px;
  width: 100%;
`;
export const SelectDatePicker = styled.div`
  max-width: 260px;
  width: 100%;
  .ant-picker {
    min-height: 40px !important;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
  }
`;
export const WrapperLabel = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 1.3rem;
  color: #6d737a;
`;
export const ButtonSearch = styled(Button)`
  height: 40px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgb(22, 119, 255);
  margin-top: 15px;
`;
